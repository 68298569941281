<template>
    <div class="contact">
        <ContactUs :isLanding="false"></ContactUs>
    </div>
</template>

<script>
export default {
    components: {
        ContactUs: () => import('@/components/landing-page/ContactUs')
    },
}
</script>

<style lang="scss" scoped>
// .contact {
//     background-image: url('~@/assets/img/home_upperbg.png');
//     background-repeat: no-repeat;
//     background-size: 100%;
// }
</style>